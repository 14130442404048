.navbar-nav a {
  color: #2c2d30;
}

@media screen and (max-width: 912px) {
  .navbar .navbar-nav {
    margin: 0 !important;
  }
}

/* Navbar Toggle Icon  */
.navbar-toggler-icon {
  background-image: url("../assets/images/headerIcon.png");
  background-repeat: no-repeat;
  background-position: center;
}

.navbar-toggler {
  padding: 2px !important;
  transition: none !important;
  border: none !important;
}

.navbar-toggler:focus,
.btn-close:focus {
  box-shadow: none !important;
}

.nav-link-active {
  color: #fecb6f !important;
  text-decoration: underline !important;
  text-underline-offset: 10px !important;
}

/* DROP DOWN STYLE  */

@media screen and (min-width: 1024px) {
  .dropdown-menu {
    background-color: #1e272e !important;
    width: 700px !important;
    border-radius: 10px !important;
    left: 50% !important;
    transform: translateX(-55%) !important;
  }

  .custom-drop {
    width: 700px !important;
  }

  .custom-main {
    display: flex !important;
    flex-direction: column !important;
    background-color: #2d3436 !important;
    border-radius: 10px !important;
    cursor: pointer !important;
  }

  .custom-image {
    border-radius: 10px !important;
  }

  .custom-main .vertical-line {
    background-color: #fecb6f;
    height: 28px;
    width: 1px;
    margin: 0 7px;
  }

  .custom-main:hover {
    background-color: #fee9a6 !important;
    color: #1a1a1a;
  }

  .custom-main:hover .vertical-line {
    background-color: #1a1a1a !important;
  }
}

@media screen and (min-width: 990px) and (max-width: 1024px) {
  .dropdown-menu {
    width: 650px !important;
  }

  .custom-drop {
    width: 650px !important;
  }
}
