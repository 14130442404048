.subscribe-form {
  background-color: rgba(44, 45, 48, 5);
}
.subscribe-form .box {
  background: linear-gradient(
    to right,
    #fecb6f 0%,
    #636e73 50%,
    #2d3436 75%,
    #2d3436 100%
  );
  border-radius: 30px;
  padding: 25px 40px;
}

.subscribe-form .subscribe-heading,
.subscribe-form .subscribe-description {
  color: #2d3436;
}

.subscribe-form input {
  border: none;
  outline: none;
  background-color: #f9fafc;
  border-radius: 8px;
  padding: 10px 20px;
  color: #2c2d30;
}

.subscribe-form textarea {
  border: none;
  outline: none;
  background-color: #f9fafc;
  border-radius: 8px;
  padding: 10px 20px;
  color: #2c2d30;
}

.subscribe-form input[type="checkbox"]:checked {
  accent-color: #2d3436;
}

.subscribe-form .inner {
  border-bottom: 1px solid #c0c1c3;
  width: fit-content;
}

@media screen and (max-width: 540px) {
  .subscribe-form .box {
    background: linear-gradient(
      to bottom,
      #fecb6f 0%,
      #636e73 50%,
      #2d3436 75%,
      #2d3436 100%
    );
  }
}

.global-light-btn-submit {
  background-color: #fee9a6;
  border: none;
  padding: 10px 30px;
  border-radius: 15px;
  width: fit-content;
  font-family: "Manrope-ExtraBold";
  color: #2d3436;
  cursor: not-allowed;
}

/* .global-light-btn-submit.enabled {
  background-color: #fee9a6; 
  cursor: pointer;
  color: #2d3436; 
} */

.width-90 {
  width: 90%;
}

.subscribe-form .number-view {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1024px) {
  .subscribe-form .number-view {
    display: flex;
    justify-content: flex-start !important;
  }

  .width-90 {
    width: 100%;
  }
}
