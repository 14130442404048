.FAQ-main.wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.item {
  margin-top: 10px;
  /* background-color: white; */
  margin-bottom: 5px;
  padding: 0;
}

.title {
  border-bottom: 1px solid #f9fafc;
}

.title-inner {
  /* color: #2f3640; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.content {
  color: black;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.show {
  color: auto;
  max-height: 9999px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.readmore-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: black;
}

.readmore-link:hover {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: black;
}

.readmore-link svg {
  margin-right: 5px;
}

.description-ethicalessence {
  text-align: justify;
  font-size: 15px;
  line-height: 24px;
  max-width: 80%;
}

@media screen and (min-width: 1024px) {
  .result-driven .driven-heading {
    font-size: 45px;
  }
}

.email-link-sec {
  text-decoration: none;
}
