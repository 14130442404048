.core-values .slick-dots li.slick-active button:before {
  color: #f9fafc;
  font-size: 13px;
  margin-top: 20px;
}

.core-values .slick-dots li button:before {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(44, 45, 48, 0.5);
  font-size: 16px;
  margin-top: 20px;
}

@media screen and (max-width: 540px) {
  .core-values .slick-dots li.slick-active button:before {
    margin-top: 5px;
  }

  .core-values .slick-dots li button:before {
    margin-top: 5px;
  }

  .core-values .core-heading {
    margin-top: 50px;
  }
}
