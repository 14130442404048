.thankyou-banner {
  background-image: url("../assets/images/thankyouBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 550px;
}

/* @media screen and (min-width: 768px) {
  .thankyou-banner .head-box span,
  .thankyou-banner p {
    margin-left: 150px !important;
  }
} */

.back-to-home {
  background-color: transparent;
  border: 1px solid white;
  padding: 10px 30px 10px 48px;
  border-radius: 10px;
  width: fit-content;
  color: white;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  font-family: "Manrope-ExtraBold";
}

.back-to-home img {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
