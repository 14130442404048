@media screen and (min-width: 1024px) {
  .business-solution-cards {
    background-image: url("../assets/images/businesSolutionBg.png");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

@media screen and (max-width: 1023px) {
  .business-solution-cards {
    background-color: rgba(44, 45, 48, 5);
  }
}

.cards-view {
  justify-content: space-between;
  color: #f9fafc;
}

.business-solution-cards .cards-view .cards-box,
.business-solution-cards .cards-view .cards-box2,
.business-solution-cards .cards-view .cards-box3,
.business-solution-cards .cards-view .cards-box4 {
  background-color: #c0c1c3;
  min-height: 450px;
  border-radius: 20px;
  max-width: 290px;
}

.business-solution-cards .cards-view .cards-box .inner {
  background-color: #58c077;
  transform: rotate(5deg);
  min-height: 450px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.business-solution-cards .cards-view .cards-box .inner .vector-image {
  position: absolute;
  height: 70px;
  width: auto;
  bottom: 11px;
  right: -35px;
}
.business-solution-cards .cards-view .cards-box2 .inner {
  background-image: linear-gradient(
    to left,
    #9b5cff 34%,
    #856eff 47%,
    #747dff 63%,
    #6986ff 80%,
    #6689ff 100%
  );

  transform: rotate(-5deg);
  min-height: 450px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.business-solution-cards .cards-view .cards-box3 .inner {
  background-image: linear-gradient(to left, #ef671f 0%, #e44760 100%);
  transform: rotate(5deg);
  min-height: 450px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.business-solution-cards .cards-view .cards-box4 .inner {
  background-image: linear-gradient(to left, #fe6ff0 0%, #5e00d7 100%);
  transform: rotate(5deg);
  min-height: 450px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.business-solution-cards .cards-view .cards-box3 .inner .second-vector-image {
  position: absolute;
  height: 100px;
  width: auto;
  top: -38px;
  right: 0;
}

@media screen and (max-width: 540px) {
  .business-solution-cards .cards-view .cards-box .inner {
    transform: none;
  }

  .business-solution-cards .cards-view .cards-box2 .inner {
    transform: none;
  }
  .business-solution-cards .cards-view .cards-box3 .inner {
    transform: none;
  }

  .business-solution-cards .cards-view .cards-box4 .inner {
    transform: none;
  }

  .cards-view {
    justify-content: center;
  }
}

@media screen and (max-width: 320px) {
  .business-solution-cards .cards-view .cards-box .inner .vector-image {
    right: -9px;
  }
}

@media screen and (max-width: 912px) {
  .business-solution-cards .cards-view .cards-box .inner .vector-image {
    display: none;
  }
  .business-solution-cards .cards-view .cards-box3 .inner .second-vector-image {
    display: none;
  }
}

.business-solution-cards .slick-slider .slick-slide {
  text-align: center;
}

.business-solution-cards .slick-prev:before {
  content: "" !important;
}

.business-solution-cards .slick-prev {
  background-image: url("../assets/images/testimonialLeftArrow.png") !important;
  background-repeat: no-repeat !important;
  background-repeat: no-repeat !important;
  height: 45px !important;
  width: 45px !important;
}

.business-solution-cards .slick-next:before {
  content: "" !important;
}

.business-solution-cards .slick-next {
  background-image: url("../assets/images/rightArrow.png") !important;
  background-repeat: no-repeat !important;
  background-repeat: no-repeat !important;
  height: 45px !important;
  width: 45px !important;
}

.business-solution-cards .slick-dots li.slick-active button:before {
  color: #f9fafc;
  font-size: 13px;
  margin: 10px 0;
}

.business-solution-cards .slick-dots li button:before {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(44, 45, 48, 0.5);
  font-size: 16px;
  margin: 10px 0;
}
