

.video-testimonial .thumbnail{
    filter: brightness(0.7);
    object-fit: cover;
}

.video-testimonial .play-img{
    position:absolute; 
    top:50%;
    left:50%; 
    cursor:pointer;
    z-index: 5; 
    transform: translate(-50%,-50%);
}