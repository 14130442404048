.testimonial-main .slick-arrow {
  position: absolute;
  top: 50%;
}

.testimonial-main .slick-arrow .slick-arrow .slick-prev {
  background-image: url("../assets/images/testimonialLeftArrow.png");
}

.testimonial-main .slick-prev:before {
  content: "";
}

.testimonial-main .slick-prev {
  background-image: url("../assets/images/testimonialLeftArrow.png");
  background-repeat: no-repeat;
  height: 45px;
  width: 45px;
}

.testimonial-main .slick-next:before {
  content: "";
}

.testimonial-main .slick-next {
  background-image: url("../assets/images/testimonialRightArrow.png");
  background-repeat: no-repeat;
  height: 45px;
  width: 45px;
}

.testimonial-main .slick-dots li.slick-active button:before {
  color: #f9fafc;
  font-size: 13px;
  margin: 10px 0;
}

.testimonial-main .slick-dots li button:before {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(44, 45, 48, 0.5);
  font-size: 16px;
  margin: 10px 0;
}
