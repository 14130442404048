/* @media screen and (min-width: 921px) {
  .price-options {
    background-image: url("../assets/images/priceOptioncardBg.png");
    background-color: rgba(44, 45, 48, 5);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 1200px;
    color: #f9fafc;
  }

  .price-options .price-main {
    margin-top: 300px;
  }
} */

.price-options {
  background-color: rgba(44, 45, 48, 5);
  color: #f9fafc;
}

@media screen and (max-width: 920px) {
  .price-options {
    background-color: rgba(44, 45, 48, 5) !important;
    background-image: none;
    color: #f9fafc;
  }
}

.price-options .price-text {
  color: #c0c1c3;
}

.price-options .price-heading {
  border-bottom: 1px solid #f9fafc;
  padding: 25px 0;
  width: 75%;
}

.price-options .price-btn {
  color: #f9fafc;
  border: 1px solid #f9fafc;
  border-radius: 15px;
  padding: 10px 40px;
  width: fit-content;
  background-color: transparent;
  font-family: "Manrope-ExtraBold";
}
.price-options .price-btn:hover {
  border: none;
  background-color: #fee9a6;
  color: #2d3436;
}

@media screen and (max-width: 540px) {
  .price-options .price-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .price-options .price-btn {
    padding: 10px 45px;
  }

  .price-options .price-heading {
    padding: 5px 0;
    width: 100%;
  }
}

/* .price-box .inner {
  box-shadow: 0 0 2px 0 #c0c1c3;
  border-radius: 10px;
  scrollbar-width: thin;
  scrollbar-color: #fecb6f #f0f0f0;
}

.price-box .inner .custom-scroll {
  height: 400px;
  overflow: auto;
}

.price-box .inner::-webkit-scrollbar {
  width: 6px;
}

.price-box .inner::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.price-box .inner::-webkit-scrollbar-thumb {
  background-color: #fecb6f;
  border-radius: 3px;
} */

.price-box .inner {
  box-shadow: 0 0 2px 0 #c0c1c3;
  border-radius: 10px;
}

.price-box .inner .custom-scroll {
  height: 400px;
  overflow: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #fecb6f #f0f0f0; /* For Firefox */
  scrollbar-width: 6px;
}

/* For WebKit browsers (Chrome, Safari) */
.price-box .inner .custom-scroll::-webkit-scrollbar {
  width: 6px;
}

.price-box .inner .custom-scroll::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.price-box .inner .custom-scroll::-webkit-scrollbar-thumb {
  background-color: #fecb6f;
  border-radius: 3px;
}
