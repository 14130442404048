.story-card .story-main {
  display: flex;
  flex-direction: column;
  align-items: start;
}

@media screen and (max-width: 540px) {
  .story-card .story-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.story-card .slick-dots li button:before {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(44, 45, 48, 0.5);
  font-size: 10px;
  margin: 10px 0;
}
