.about-banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-banner .about-heading {
  font-size: 37px;
}

@media screen and (min-width: 768px) and (max-width: 912px) {
  .about-banner {
    min-height: 200px;
  }
}

@media screen and (max-width: 540px) {
  .about-banner {
    min-height: 500px;
  }
  .about-banner .about-image {
    justify-content: flex-end !important;
    align-items: center !important;
    margin-top: 50px;
  }
  .about-banner .about-image img {
    width: auto;
    height: 250px;
  }

  .about-banner .about-heading {
    font-size: 30px;
  }
}
