.global-light-btn {
  background-color: #fee9a6;
  border: none;
  padding: 10px 30px;
  border-radius: 15px;
  width: fit-content;
  font-family: "Manrope-ExtraBold";
  color: #2d3436;
}

.global-dark-btn {
  background-color: #fecb6f;
  border: none;
  padding: 10px 30px;
  border-radius: 10px;
  width: fit-content;
  color: #2d3436;
  font-family: "Manrope-ExtraBold";
}

.global-dark-transparent {
  background-color: transparent;
  border: 1px solid white;
  padding: 10px 30px 10px 48px;
  border-radius: 10px;
  width: fit-content;
  color: white;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  font-family: "Manrope-ExtraBold";
  margin-left: 1rem;
}

.dark-global-btn {
  background-color: #fee9a6;
  width: fit-content;
  color: white;
  padding: 10px 30px;
  border-radius: 10px;
  width: fit-content;
  color: #010101;
  border: none;
  font-family: "Manrope-ExtraBold";
}

@media screen and (max-width: 320px) {
  .global-dark-btn {
    padding: 8px 18px;
  }
  .global-dark-transparent {
    padding: 8px 10px 8px 40px;
    margin-left: 18px !important;
  }
}

.global-dark-transparent img {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.text-theme {
  color: #ffb800;
}
.text-theme-second {
  color: #fecb6f;
}

.text-theme-white {
  color: #f9fafc;
}

.text-theme-light-white {
  color: #c0c1c3;
}

.text-pink {
  color: #f74a9b;
}
.text-light-blue {
  color: #66c2ff;
}

.global-background {
  background-color: rgba(44, 45, 48, 5);
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .responsive-width {
    width: 75%;
  }
}

/* -----FONTS----- */
.font-face-Manrope-Medium {
  font-family: "Manrope-Medium";
}

.font-face-Bold {
  font-family: "PTSerif-Bold";
}

.font-face-Manrope-Extra-Bold {
  font-family: "Manrope-ExtraBold";
}

.font-face-Manrope-Regular {
  font-family: "Manrope-Regular";
}

.font-face-Aguafina-Script-Regular {
  font-family: "Aguafina-Script";
}
.font-face-Manrope-Bold {
  font-family: "Manrope-Bold";
}
.font-face-Poppins-Bold {
  font-family: "Poppins-Bold";
}
.font-face-Poppins-Semi-Bold {
  font-family: "Poppins-Semi-Bold";
}
.font-face-Poppins-Medium {
  font-family: "Poppins-Medium";
}
.font-face-Poppins-Regular {
  font-family: "Poppins-Regular";
}

/* ---CUSTOME SERVICE CSS----- */

@media screen and (max-width: 540px) {
  .custom-services .custom-inner {
    flex-direction: row-reverse;
  }
}

/* --------Call Icon css --------- */

@media screen and (min-width: 720px) and (max-width: 1023px) {
  .receiver-icon {
    display: flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .receiver-icon {
    display: none !important;
  }
}

@media screen and (max-width: 375px) {
  .receiver-icon {
    left: 70% !important;
  }
}

.receiver-icon {
  position: absolute;
  z-index: 9999;
  left: 78%;
  height: 50px;
  width: fit-content;
  top: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.whatsapp-icon {
  position: fixed;
  bottom: 25px;
  height: 50px;
  left: 5px;
  z-index: 9999;
  cursor: pointer;
}
