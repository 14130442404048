.hero-main .hero-heading {
  color: #f9fafc;
}

/* .hero-main .hero-heading {
  line-height: 70px;
  color: #f9fafc;
  font-size: 60px;
} */

@media screen and (min-width: 540px) and (max-width: 912px) {
  .hero-main {
    min-height: 680px !important;
    padding: 40px 0;
  }

  .hero-main .hero-paragraph {
    width: 90%;
  }
}

@media screen and (min-width: 921px) {
  .hero-main {
    /* background-image: url("../assets/images/heroBG.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 1150px; */
    background-color: #2d3635;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
  }
  .hero-main .web-image {
    display: block;
  }
  .hero-main .tablet-image {
    display: none;
  }
  .hero-main .mobile-image {
    display: none;
  }
}

@media screen and (min-width: 540px) and (max-width: 920px) {
  .hero-main {
    background-color: #2d3436;
  }
  .hero-main .web-image {
    display: none;
  }
  .hero-main .tablet-image {
    display: block;
  }
  .hero-main .mobile-image {
    display: none;
  }

  /* .hero-main .hero-heading {
    line-height: 50px;
    color: #f9fafc;
    font-size: 40px;
  } */
}

@media screen and (max-width: 540px) {
  .hero-main {
    background-color: #2d3436;
  }
  .hero-main .web-image {
    display: none;
  }
  .hero-main .tablet-image {
    display: none;
  }
  .hero-main .mobile-image {
    display: block;
  }

  .hero-main .hero-heading {
    line-height: 50px;
    color: #f9fafc;
    font-size: 40px;
  }
}
