@font-face {
  font-family: "PTSerif-Bold";
  src: local("PTSerif-Bold"),
    url("./assets/fonts/Pt-Serif/PTSerif-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope-Medium";
  src: local("Manrope-Medium"),
    url("./assets/fonts/Manrope/Manrope-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope-ExtraBold";
  src: local("Manrope-ExtraBold"),
    url("./assets/fonts/Manrope/Manrope-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope-Regular";
  src: local("Manrope-Regular"),
    url("./assets/fonts/Manrope/Manrope-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Aguafina-Script";
  src: local("Aguafina-Script"),
    url("./assets/fonts/Aguafina-Script/AguafinaScript-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "Manrope-Bold";
  src: local("Manrope-Bold"),
    url("./assets/fonts/Manrope/Manrope-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Semi-Bold";
  src: local("Poppins-Semi-Bold"),
    url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url("./assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
