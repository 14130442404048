.footer-main {
  background-color: rgba(44, 45, 48, 5);
}

.footer-main a {
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  .footer-main .footer-heading {
    font-size: 45px;
  }
}

.footer-main .list-item {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border-color: #c0c1c3;
  border: 1px solid #c0c1c3;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 540px) {
  .footer-main .follow-box {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
  }

  .footer-main .infobox {
    display: flex !important;
    flex-direction: column !important;
  }
  .footer-main .vector-box {
    justify-content: center !important;
  }
}

.email-link {
  text-decoration: none;
}
